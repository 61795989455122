import React from 'react';
import { Button } from 'react-bootstrap';
import { BsLinkedin, BsEnvelopeAtFill } from 'react-icons/bs';
import '../App.css';

function Footer() {
  return (
    <div className="footer">
        <div className="landing-header" style={{ "font-size": "32px"}}>Contact Me</div>
        <Button className="footer-button" variant="dark" size="sm" href="mailto:joshua.rico12@gmail.com"><BsEnvelopeAtFill/></Button>
        <Button className="footer-button" variant="dark" size="sm" href="https://www.linkedin.com/in/joshua-rico"><BsLinkedin/></Button>
    </div>
  );
}

export default Footer;
