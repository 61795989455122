import React, { useEffect, useState, Suspense } from 'react';
import { Container, Col, Row, Image, Badge, Stack, Spinner } from 'react-bootstrap';
import { FaExternalLinkAlt } from "react-icons/fa";
import Headshot from '../images/headshot-3.JPG';
import '../App.css';

const LazyPortfolio = React.lazy(() => import('../components/Portfolio.js'));
const cache = {};

function Landing() {
    const [ projects, setProjects ] = useState([]);

    useEffect(() => { 
        //development server
        // const url = 'http://localhost:5000/api/projects';
        
        // production server
        const url = 'https://joshua-rico-personal-website-api.vercel.app/api/projects';

        if (cache[url]) {
            setProjects(cache[url]);
        } else {
            fetch(url, {
              method: "GET",
            })
              .then((response) => response.json())
              .then((data) => {setProjects(data)})
              .catch((error) => {console.log(error)});
        }
      }, [])


    return (
        <Container>
            <Row className="landing">
                <Col xs={12} md={8}>
                    <Row style={{"margin-top": "20px"}}>
                        <Col >
                            <div className="landing-header">Hi, I'm Josh</div>
                            <div className="landing-about-body">
                                I am a passionate and dedicated Software Developer, who recently graduated
                                from the University of Hawaii at Manoa with a B.S. in Computer Science. I am eager
                                to contribute to projects in the industry, refine my skills, and broaden my horizons.
                            </div>
                        </Col>
                        <Col md={3} className="image-column">
                            <Image height={150} width={150} src={Headshot} roundedCircle/>
                        </Col>
                    </Row>
                    <Row>
                        <div className="landing-header">Education</div>
                        <Col className="image-column" xs={2} md={1}>
                            <Image height="50px" width="50px" src="https://i.pinimg.com/474x/03/fe/a1/03fea1a7d3c613eea016449ea4b209fc.jpg" roundedCircle/>
                        </Col>
                        <Col>
                            <div className="landing-education-header">
                                <h4>
                                    <a href="https://manoa.hawaii.edu/" className="landing-link">
                                        University of Hawaii at Manoa <FaExternalLinkAlt className="link-icon" style={{fontSize: '10px'}}/>
                                    </a>
                                </h4>
                                <h4>
                                    2020-2024
                                </h4>
                            </div>
                            <div className="landing-education-body">
                                Bachelor's Degree in Computer Science
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="landing-header">Skills</div>
                        <Stack direction="horizontal" gap={2}>
                            <Badge pill bg="dark">React</Badge>
                            <Badge pill bg="dark">NodeJS</Badge>
                            <Badge pill bg="dark">Flask</Badge>
                            <Badge pill bg="dark">Java</Badge>
                            <Badge pill bg="dark">C++</Badge>
                            <Badge pill bg="dark">Python</Badge>
                        </Stack>
                    </Row>
                    <Row>
                        <div className="landing-header">Projects</div>
                        <Suspense fallback={<Spinner animation="grow" variant="secondary"/>}>
                            <LazyPortfolio projects={projects}/>
                        </Suspense>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default Landing;
