import './index.css';
import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from './pages/Landing';
import Footer from './components/Footer';

function App() {
  return (
      <Router>
          <div className="main">
              <Routes>
                  <Route path="/" element={<Landing/>} />
              </Routes>
          </div>
          <Footer/>
      </Router>
  );
}

export default App;
